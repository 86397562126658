import React from 'react';
import FinanceProject3 from '../components/FinanceProject3';

const Project3: React.FC = () => {
  return (
    <div className="container mx-auto px-4 py-8 finance-project-3">
      <h1 className="text-3xl font-bold mb-6 text-center">Algorithmic Portfolio Allocator</h1>
      <FinanceProject3 />
    </div>
  );
};

export default Project3;
