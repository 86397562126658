import React from 'react';
import FinanceProject1 from '../components/FinanceProject1';

const Project1: React.FC = () => {
  return (
    <div className="text-center">
      <h1 className="text-3xl font-bold mb-6">Portfolio Backtester</h1>
      <FinanceProject1 />
    </div>
  );
};

export default Project1;
