import React, { useState } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import axios from 'axios';

interface Stock {
  ticker: string;
  shares: number;
}

interface PerformanceData {
  date: string;
  portfolioReturn: number;
  sp500Return: number;
}

const FinanceProject1: React.FC = () => {
  const [portfolio, setPortfolio] = useState<Stock[]>([{ ticker: '', shares: 0 }]);
  const [startDate, setStartDate] = useState<string>('');
  const [endDate, setEndDate] = useState<string>('');
  const [results, setResults] = useState<any>(null);
  const [performanceData, setPerformanceData] = useState<PerformanceData[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const addStock = () => {
    setPortfolio(prevPortfolio => [...prevPortfolio, { ticker: '', shares: 0 }]);
  };

  const updateStock = (index: number, field: keyof Stock, value: string | number) => {
    setPortfolio(prevPortfolio => {
      const updatedPortfolio = [...prevPortfolio];
      updatedPortfolio[index] = { ...updatedPortfolio[index], [field]: value };
      return updatedPortfolio;
    });
  };

  const removeStock = (index: number) => {
    setPortfolio(prevPortfolio => prevPortfolio.filter((_, i) => i !== index));
  };

  const runBacktest = async () => {
    setResults(null);
    setPerformanceData([]);
    setIsLoading(true);
    setError(null);
    
    if (new Date(startDate) >= new Date(endDate)) {
      setError('Error: Start date must be before end date.');
      setIsLoading(false);
      return;
    }
    
    if (!startDate || !endDate) {
      setError('Error: Please select both start and end dates.');
      setIsLoading(false);
      return;
    }

    if (!portfolio || portfolio.length === 0 || portfolio.some(stock => !stock.ticker || stock.shares <= 0)) {
      setError('Error: Please ensure all stocks have a valid ticker and positive number of shares.');
      setIsLoading(false);
      return;
    }

    try {
      const response = await axios.post('https://lean-finance.onrender.com/backtest', {
        portfolio,
        startDate,
        endDate
      });

      console.log('Backend response:', response.data);

      const { performanceData, portfolioReturn, sp500Return, portfolioSharpeRatio, sp500SharpeRatio } = response.data;

      if (!performanceData || !Array.isArray(performanceData) || performanceData.length === 0) {
        throw new Error('Invalid or empty performance data received from the server');
      }

      const formattedPerformanceData = performanceData.map((data: any, index: number) => {
        const portfolioValue = parseFloat(data.portfolioValue);
        const sp500Value = parseFloat(data.sp500Value);
        const initialPortfolioValue = parseFloat(performanceData[0].portfolioValue);
        const initialSp500Value = parseFloat(performanceData[0].sp500Value);

        console.log(`Data point ${index}:`, {
          date: data.date,
          portfolioValue,
          sp500Value,
          initialPortfolioValue,
          initialSp500Value
        });

        return {
          date: new Date(data.date).toISOString().split('T')[0],
          portfolioReturn: ((portfolioValue / initialPortfolioValue) - 1) * 100,
          sp500Return: ((sp500Value / initialSp500Value) - 1) * 100
        };
      });

      console.log('Formatted performance data:', formattedPerformanceData);

      setPerformanceData(formattedPerformanceData);
      setResults({ portfolioReturn, sp500Return, portfolioSharpeRatio, sp500SharpeRatio });
    } catch (error) {
      console.error('Error running backtest:', error);
      setError(`Error running backtest: ${error instanceof Error ? error.message : String(error)}`);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="bg-gradient-to-br from-purple-900 to-black text-white p-8 min-h-screen">
      <div className="max-w-3xl mx-auto space-y-8">
        
        <div className="space-y-4">
          {portfolio.map((stock, index) => (
            <div key={index} className="flex space-x-2 justify-center items-center">
              <input
                type="text"
                value={stock.ticker}
                onChange={(e) => updateStock(index, 'ticker', e.target.value.toUpperCase())}
                placeholder="Stock Ticker"
                className="w-2/5 bg-purple-800 text-white placeholder-purple-300 rounded-lg p-2 focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-300"
              />
              <input
                type="number"
                value={stock.shares}
                onChange={(e) => updateStock(index, 'shares', Number(e.target.value))}
                placeholder="Shares"
                className="w-2/5 bg-purple-800 text-white placeholder-purple-300 rounded-lg p-2 focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-300"
              />
              <button
                onClick={() => removeStock(index)}
                className="w-1/5 p-2 bg-red-600 text-white rounded-lg hover:bg-red-700 transition duration-300 shadow-md"
              >
                Remove
              </button>
            </div>
          ))}
          <div className="flex justify-center mt-4">
            <button
              onClick={addStock}
              className="px-6 py-2 bg-green-600 text-white rounded-lg hover:bg-green-700 transition duration-300 shadow-md"
            >
              Add Stock
            </button>
          </div>
        </div>
        
        <div className="flex justify-center space-x-4">
          <div className="w-1/2">
            <label htmlFor="startDate" className="block text-sm font-medium text-gray-300 mb-1">Start Date</label>
            <input
              id="startDate"
              type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              className="w-full bg-purple-800 text-white rounded-lg p-2 focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-300"
            />
          </div>
          <div className="w-1/2">
            <label htmlFor="endDate" className="block text-sm font-medium text-gray-300 mb-1">End Date</label>
            <input
              id="endDate"
              type="date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              className="w-full bg-purple-800 text-white rounded-lg p-2 focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-300"
            />
          </div>
        </div>
        
        <div className="flex justify-center mt-6">
          <button
            onClick={runBacktest}
            disabled={isLoading}
            className="px-8 py-3 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition duration-300 shadow-md text-lg font-semibold"
          >
            {isLoading ? 'Running...' : 'Run Backtest'}
          </button>
        </div>
        
        {isLoading && <p className="text-center">Loading... This may take a while.</p>}
        {error && <p className="text-center text-red-500">{error}</p>}
        
        {results && (
          <div className="mt-8 p-6 bg-purple-800 rounded-lg shadow-md">
            <h3 className="text-2xl font-semibold mb-4 text-center">Results</h3>
            <table className="w-full text-center border-collapse">
              <thead>
                <tr>
                  <th className="border-b-2 border-purple-600 py-2"></th>
                  <th className="border-b-2 border-purple-600 py-2 px-4">Portfolio</th>
                  <th className="border-b-2 border-purple-600 py-2 px-4">S&P 500</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="border-b border-purple-600 py-2 font-semibold">Return</td>
                  <td className="border-b border-purple-600 py-2">{results.portfolioReturn.toFixed(2)}%</td>
                  <td className="border-b border-purple-600 py-2">{results.sp500Return.toFixed(2)}%</td>
                </tr>
                <tr>
                  <td className="py-2 font-semibold">Sharpe Ratio</td>
                  <td className="py-2">{results.portfolioSharpeRatio.toFixed(2)}</td>
                  <td className="py-2">{results.sp500SharpeRatio.toFixed(2)}</td>
                </tr>
              </tbody>
            </table>
          </div>
        )}
        
        {performanceData.length > 0 && (
          <div className="mt-8 p-6 bg-purple-800 rounded-lg shadow-md">
            <h3 className="text-2xl font-semibold mb-4 text-center">Performance Graph</h3>
            <ResponsiveContainer width="100%" height={400}>
              <LineChart data={performanceData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis 
                  dataKey="date" 
                  tick={{ fill: 'white' }}
                  tickFormatter={(tick) => new Date(tick).toLocaleDateString()}
                />
                <YAxis 
                  tick={{ fill: 'white' }}
                  tickFormatter={(tick) => `${tick.toFixed(2)}%`}
                  domain={['auto', 'auto']}
                />
                <Tooltip 
                  contentStyle={{ backgroundColor: '#4B5563', border: 'none' }}
                  labelStyle={{ color: 'white' }}
                  itemStyle={{ color: 'white' }}
                  formatter={(value: number) => isNaN(value) ? 'N/A' : `${value.toFixed(2)}%`}
                />
                <Legend />
                <Line 
                  type="monotone" 
                  dataKey="portfolioReturn" 
                  stroke="#8884d8" 
                  name="Portfolio" 
                  dot={false}
                  isAnimationActive={false}
                />
                <Line 
                  type="monotone" 
                  dataKey="sp500Return" 
                  stroke="#82ca9d" 
                  name="S&P 500" 
                  dot={false}
                  isAnimationActive={false}
                />
              </LineChart>
            </ResponsiveContainer>
          </div>
        )}
      </div>
    </div>
  );
};

export default FinanceProject1;
