import React from 'react';
import { FaLinkedin } from 'react-icons/fa';

const Footer: React.FC = () => {
  return (
    <footer className="bg-gray-800 text-white py-4" style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
      <div style={{ 
        maxWidth: '1200px', 
        margin: '0 auto', 
        padding: '0 20px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
      }}>
        <div style={{ flex: '1', textAlign: 'left' }}>
          <a href="https://bellsdrones.com" target="_blank" rel="noopener noreferrer" className="text-gray-200 hover:text-white flex items-center">
            Bell's Drones
          </a>
        </div>
        <div style={{ flex: '1', textAlign: 'center' }}>
          <p>&copy; 2024 Austin Bell. All rights reserved.</p>
        </div>
        <div style={{ flex: '1', textAlign: 'right' }}>
          <a href="https://www.linkedin.com/in/austin-bell-9a0082180/" target="_blank" rel="noopener noreferrer" className="text-blue-400 hover:text-blue-300">
            <FaLinkedin size={36} />
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
