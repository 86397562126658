import React from 'react';

const AboutMe: React.FC = () => {
  return (
    <section className="bg-white rounded-lg shadow-md p-6 max-w-2xl mx-auto">
      <h2 className="text-2xl font-bold mb-4 text-center">About Me</h2>
      <p className="text-gray-700 text-center">
        Hello! I'm a finance professional with a passion for creating new
        projects. I currently work as an Auditor at a community bank in Kentucky.
        This is a side project that I have built to learn more about web development.
        My current project is training a machine learning model to predict scores and winners 
        in the NBA for the 2024 season. 
      </p>
    </section>
  );
};

export default AboutMe;
