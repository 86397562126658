import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import Footer from './components/Footer';
import Home from './pages/Home';
import Project1 from './pages/Project1';
import Project2 from './pages/Project2';
import Project3 from './pages/Project3';
import './App.css';

const App: React.FC = () => {
  return (
    <Router>
      <div className="flex flex-col min-h-screen">
        <div className="color-splash splash-1"></div>
        <div className="color-splash splash-2"></div>
        <main className="flex-grow container mx-auto px-4">
          <div className="main-content">
            <div className="text-center">
              <h1 className="text-4xl font-bold mb-4">Austin Bell</h1>
              <p className="text-xl mb-8">Finance Expert & Tinkerer</p>
            </div>
            <div className="button-container">
              <div className="about-me-button">
                <Link to="/" className="btn btn-primary">Home</Link>
              </div>
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/project1" element={<Project1 />} />
                <Route path="/project2" element={<Project2 />} />
                <Route path="/project3" element={<Project3 />} />
              </Routes>
              <div className="project-buttons">
                <Link to="/project1" className="btn btn-secondary">Backtester</Link>
                <Link to="/project2" className="btn btn-secondary">Equity Dashboard</Link>
                <Link to="/project3" className="btn btn-secondary">Portfolio Allocator</Link>
              </div>
            </div>
          </div>
        </main>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
