import React from 'react';
import FinanceProject2 from '../components/FinanceProject2';

const Project2: React.FC = () => {
  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-6 text-center">Equity Dashboard</h1>
      <FinanceProject2 />
    </div>
  );
};

export default Project2;
