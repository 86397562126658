import React, { useState, useMemo } from 'react';
import axios from 'axios';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

interface StockData {
  date: string;
  price: number;
}

interface NewsItem {
  headline: string;
}

interface InsiderTrade {
  insider: string;
  shares: number;
  type: string;
}

interface InstitutionalHolder {
  "Date Reported": string;
  Holder: string;
  Shares: number;
  Value: number;
  pctHeld: number;
}

interface EarningsHistory {
  epsActual: number;
  epsDifference: number;
  epsEstimate: number;
  surprisePercent: number;
}

interface AnalystRatings {
  buy: number;
  hold: number;
  sell: number;
}

interface ApiResponse {
  stockData: StockData[];
  news: NewsItem[];
  insiderTrades: InsiderTrade[];
  institutionalHolders: InstitutionalHolder[];
  earningsHistory: EarningsHistory;
  analystRatings: AnalystRatings;
  analystPriceTarget: number;
}

const FinanceProject2: React.FC = () => {
  const [ticker, setTicker] = useState<string>('');
  const [data, setData] = useState<ApiResponse | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [loadStartTime, setLoadStartTime] = useState<number>(0);

  const fetchData = async () => {
    if (!ticker) return;
    setIsLoading(true);
    setLoadStartTime(new Date().getTime());
    try {
      console.log(`Fetching data for ${ticker}...`);
      const response = await axios.get<ApiResponse>(`https://lean-finance.onrender.com/api/stock/${ticker}`);
      console.log('Raw API response:', response.data);
      setData(response.data);
    } catch (error) {
      console.error('Error fetching data:', error);
      setData(null);
    } finally {
      setIsLoading(false);
    }
  };

  const normalizedStockData = useMemo(() => {
    if (!data?.stockData) return [];
    const prices = data.stockData.map(d => d.price);
    const minPrice = Math.min(...prices);
    const maxPrice = Math.max(...prices);
    const padding = (maxPrice - minPrice) * 0.1; // Add 10% padding

    return data.stockData.map(d => ({
      ...d,
      price: parseFloat(d.price.toFixed(2)) // Ensure price is rounded to 2 decimal places
    }));
  }, [data?.stockData]);

  const priceRange = useMemo(() => {
    if (normalizedStockData.length === 0) return { min: 0, max: 100 };
    const prices = normalizedStockData.map(d => d.price);
    const minPrice = Math.min(...prices);
    const maxPrice = Math.max(...prices);
    const padding = (maxPrice - minPrice) * 0.1; // Add 10% padding
    return {
      min: Math.max(0, minPrice - padding),
      max: maxPrice + padding
    };
  }, [normalizedStockData]);

  return (
    <div className="bg-white rounded-lg shadow-md p-6">
      <h2 className="text-2xl font-bold mb-4 text-center">Stock Dashboard</h2>
      <div className="mb-4 flex justify-center">
        <input
          type="text"
          value={ticker}
          onChange={(e) => setTicker(e.target.value.toUpperCase())}
          placeholder="Enter stock ticker"
          className="mt-1 block w-64 rounded-md border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
        />
        <button
          onClick={fetchData}
          disabled={isLoading}
          className="ml-2 px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 disabled:bg-blue-300"
        >
          {isLoading ? (
            <>
              Loading...
              <br />
              {new Date().getTime() - loadStartTime > 10000 && 
                "This could take up to 1 minute"
              }
            </>
          ) : 'Get Data'}
        </button>
      </div>

      {data && (
        <div className="flex flex-col items-center">
          <div className="mb-6 w-full flex flex-col md:flex-row">
            <div className="w-full md:w-2/3 pr-4">
              {normalizedStockData.length > 0 && (
                <div className="mb-6 w-full">
                  <h3 className="text-xl font-semibold mb-2 text-center">Stock Price Chart</h3>
                  <ResponsiveContainer width="100%" height={400}>
                    <LineChart data={normalizedStockData}>
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="date" />
                      <YAxis 
                        domain={[priceRange.min, priceRange.max]} 
                        tickFormatter={(value) => `$${value.toFixed(2)}`}
                      />
                      <Tooltip 
                        formatter={(value: number) => [`$${value.toFixed(2)}`, "Price"]}
                        labelFormatter={(label) => `Date: ${label}`}
                      />
                      <Legend />
                      <Line type="monotone" dataKey="price" stroke="#8884d8" activeDot={{ r: 8 }} />
                    </LineChart>
                  </ResponsiveContainer>
                </div>
              )}
            </div>
            <div className="w-full md:w-1/3 mt-4 md:mt-0">
              <h3 className="text-xl font-semibold mb-2 text-center">Earnings History</h3>
              {data.earningsHistory && Object.keys(data.earningsHistory).length > 0 ? (
                <table className="w-full border-collapse">
                  <thead>
                    <tr>
                      <th className="border px-2 py-1 text-sm">EPS Act.</th>
                      <th className="border px-2 py-1 text-sm">EPS Est.</th>
                      <th className="border px-2 py-1 text-sm">Diff.</th>
                      <th className="border px-2 py-1 text-sm">Surprise</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="border px-2 py-1 text-sm">${data.earningsHistory.epsActual.toFixed(2)}</td>
                      <td className="border px-2 py-1 text-sm">${data.earningsHistory.epsEstimate.toFixed(2)}</td>
                      <td className="border px-2 py-1 text-sm">${data.earningsHistory.epsDifference.toFixed(2)}</td>
                      <td className="border px-2 py-1 text-sm">{(data.earningsHistory.surprisePercent * 100).toFixed(2)}%</td>
                    </tr>
                  </tbody>
                </table>
              ) : (
                <p>No earnings history available</p>
              )}
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 w-full max-w-6xl">
            {/* Insider Trades section */}
            <div className="text-center">
              <h3 className="text-xl font-semibold mb-2">Insider Trades</h3>
              {Array.isArray(data.insiderTrades) && data.insiderTrades.length > 0 ? (
                <div className="overflow-x-auto">
                  <table className="w-full border-collapse">
                    <thead>
                      <tr>
                        <th className="border px-4 py-2 whitespace-nowrap">Insider</th>
                        <th className="border px-4 py-2 whitespace-nowrap">Type</th>
                        <th className="border px-4 py-2 whitespace-nowrap">Shares</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.insiderTrades.map((trade, index) => (
                        <tr key={index}>
                          <td className="border px-4 py-2 whitespace-nowrap overflow-hidden text-ellipsis max-w-xs">{trade.insider}</td>
                          <td className="border px-4 py-2 whitespace-nowrap">{trade.type}</td>
                          <td className="border px-4 py-2 whitespace-nowrap">{typeof trade.shares === 'number' ? trade.shares.toLocaleString() : 'N/A'}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              ) : (
                <p>No insider trades available</p>
              )}
            </div>

            {/* Institutional Holders section */}
            <div className="text-center">
              <h3 className="text-xl font-semibold mb-2">Institutional Holders</h3>
              {Array.isArray(data.institutionalHolders) && data.institutionalHolders.length > 0 ? (
                <div className="overflow-x-auto">
                  <table className="w-full border-collapse">
                    <thead>
                      <tr>
                        <th className="border px-4 py-2 whitespace-nowrap">Holder</th>
                        <th className="border px-4 py-2 whitespace-nowrap">Shares</th>
                        <th className="border px-4 py-2 whitespace-nowrap">Value</th>
                        <th className="border px-4 py-2 whitespace-nowrap">% Held</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.institutionalHolders.map((holder, index) => (
                        <tr key={index}>
                          <td className="border px-4 py-2 whitespace-nowrap overflow-hidden text-ellipsis max-w-xs">{holder.Holder}</td>
                          <td className="border px-4 py-2 whitespace-nowrap">{holder.Shares.toLocaleString()}</td>
                          <td className="border px-4 py-2 whitespace-nowrap">${holder.Value.toLocaleString()}</td>
                          <td className="border px-4 py-2 whitespace-nowrap">{(holder.pctHeld).toFixed(2)}%</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              ) : (
                <p>No institutional holders data available</p>
              )}
            </div>
          </div>

          {/* Analyst Price Target section */}
          <div className="mt-6 text-center">
            <h3 className="text-xl font-semibold mb-2">Analyst Price Target</h3>
            <p>{typeof data.analystPriceTarget === 'number' ? `$${data.analystPriceTarget.toFixed(2)}` : 'N/A'}</p>
          </div>
        </div>
      )}

      {!isLoading && !data && (
        <p className="text-gray-600 mt-4 text-center">No data available. Please enter a valid stock ticker and click "Get Data".</p>
      )}
    </div>
  );
};

export default FinanceProject2;
