import React, { useState, useEffect } from 'react';

interface Stock {
  symbol: string;
  returns: number;
  volatility: number;
}

const FinanceProject3: React.FC = () => {
  const [stocks, setStocks] = useState<Stock[]>([]);
  const [age, setAge] = useState<number>(30);
  const [riskTolerance, setRiskTolerance] = useState<'low' | 'medium' | 'high'>('medium');
  const [newStock, setNewStock] = useState<string>('');

  // Placeholder function to fetch stock data (replace with API call later)
  const fetchStockData = async (symbol: string): Promise<Stock> => {
    // Simulating API call with random data
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve({
          symbol,
          returns: Math.random() * 0.2 - 0.1, // Random return between -10% and 10%
          volatility: Math.random() * 0.3 + 0.1, // Random volatility between 10% and 40%
        });
      }, 500);
    });
  };

  const addStock = async () => {
    if (newStock) {
      const stockData = await fetchStockData(newStock);
      setStocks([...stocks, stockData]);
      setNewStock('');
    }
  };

  const calculateAllocation = () => {
    if (stocks.length === 0) return [];

    const riskFreeRate = 0.02; // Assuming 2% risk-free rate

    // Calculate portfolio metrics
    const totalRisk = stocks.reduce((sum, stock) => sum + stock.volatility, 0);
    const avgReturn = stocks.reduce((sum, stock) => sum + stock.returns, 0) / stocks.length;

    // Risk Parity allocation
    const riskParityWeights = stocks.map(stock => 1 / stock.volatility / totalRisk);

    // Maximum Sharpe Ratio allocation
    const sharpeRatios = stocks.map(stock => (stock.returns - riskFreeRate) / stock.volatility);
    const maxSharpeIndex = sharpeRatios.indexOf(Math.max(...sharpeRatios));
    const maxSharpeWeights = stocks.map((_, index) => index === maxSharpeIndex ? 1 : 0);

    // Maximum Return allocation
    const maxReturnIndex = stocks.indexOf(stocks.reduce((prev, current) => (prev.returns > current.returns) ? prev : current));
    const maxReturnWeights = stocks.map((_, index) => index === maxReturnIndex ? 1 : 0);

    // Blend weights based on risk tolerance
    let finalWeights: number[];
    switch (riskTolerance) {
      case 'low':
        finalWeights = maxSharpeWeights;
        break;
      case 'medium':
        finalWeights = riskParityWeights;
        break;
      case 'high':
        finalWeights = maxReturnWeights;
        break;
    }

    // Adjust weights based on age (younger = more aggressive)
    const ageAdjustment = Math.max(0, (100 - age) / 100);
    finalWeights = finalWeights.map(weight => weight * (1 + ageAdjustment));

    // Normalize weights to sum to 100%
    const totalWeight = finalWeights.reduce((sum, weight) => sum + weight, 0);
    finalWeights = finalWeights.map(weight => (weight / totalWeight) * 100);

    return stocks.map((stock, index) => ({
      ...stock,
      allocation: finalWeights[index],
    })).sort((a, b) => b.allocation - a.allocation);
  };

  const removeStock = (symbolToRemove: string) => {
    setStocks(stocks.filter(stock => stock.symbol !== symbolToRemove));
  };

  return (
    <div className="bg-white rounded-lg shadow-md p-6 max-w-3xl mx-auto w-full">
      <h2 className="text-2xl font-bold mb-4 text-center">Risk Parity Portfolio Allocator</h2>
      <div className="space-y-4">
        <div className="flex flex-col items-center">
          <label className="block text-sm font-medium text-gray-700">Age</label>
          <input
            type="number"
            value={age}
            onChange={(e) => setAge(Number(e.target.value))}
            className="mt-1 block w-full max-w-xs rounded-md border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
          />
        </div>
        <div className="flex flex-col items-center">
          <label className="block text-sm font-medium text-gray-700">Risk Tolerance</label>
          <select
            value={riskTolerance}
            onChange={(e) => setRiskTolerance(e.target.value as 'low' | 'medium' | 'high')}
            className="mt-1 block w-full max-w-xs rounded-md border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
          >
            <option value="low">Low (Max Sharpe Ratio)</option>
            <option value="medium">Medium (Risk Parity)</option>
            <option value="high">High (Max Returns)</option>
          </select>
        </div>
        <div className="flex flex-col items-center">
          <h3 className="text-lg font-semibold mb-2">Add Stock</h3>
          <div className="flex space-x-2 w-full max-w-xs">
            <input
              type="text"
              placeholder="Ticker Symbol"
              value={newStock}
              onChange={(e) => setNewStock(e.target.value.toUpperCase())}
              className="flex-1 rounded-md border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
            />
            <button
              onClick={addStock}
              className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
            >
              Add
            </button>
          </div>
        </div>
        <div>
          <h3 className="text-lg font-semibold mb-2 text-center">Portfolio Allocation</h3>
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200 border border-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider border-r">Symbol</th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider border-r">Allocation</th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider border-r">Returns</th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider border-r">Volatility</th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Action</th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {calculateAllocation().map((stock, index) => (
                  <tr key={index}>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 border-r">{stock.symbol}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 border-r">{stock.allocation.toFixed(2)}%</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 border-r">{(stock.returns * 100).toFixed(2)}%</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 border-r">{(stock.volatility * 100).toFixed(2)}%</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      <button
                        onClick={() => removeStock(stock.symbol)}
                        className="text-red-600 hover:text-red-900"
                      >
                        Remove
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FinanceProject3;
